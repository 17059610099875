import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Card, Icon } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Card",
  "componentId": "card",
  "description": "Cards are clickable blocks that can represent an object or option with a title and description.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Documentation in progress-----------`}</strong></p>
    <h2>{`Variants`}</h2>
    <PatternExample example={<Card.Basic content="Example card description." title="Card Title" />} mdxType="PatternExample" />
    <h3>{`Usage`}</h3>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
    <p>When displaying multiple cards, stay consistent in structure and metadata of each.</p>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>Do not use a card alone. Always use cards when multiple are used.</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Configuration`}</h2>
    <h3>{`With Icon`}</h3>
    <PatternExample example={<Card.Basic content="Example card description." title="Card Title" icon={<Icon icon="chart-bar" mdxType="Icon" />} />} mdxType="PatternExample" />
    <h2>{`Layout`}</h2>
    <PatternExample example={"..."} title="..." description={"Description about layout structure"} mdxType="PatternExample" />
    <h2>{`Content`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      